import { Node } from "components/Drawer/NodeDrawer"
import { Icon } from "components/Icon"
import Link from "components/Link"

import Collapse from "@mui/material/Collapse"
import List from "@mui/material/List"
import ListItem from "@mui/material/ListItem"
import Typography from "@mui/material/Typography"
import { styled } from "@mui/material/styles"

interface NavigationSubsection {
    nodes: Node[]
    open: boolean
}

export default function NavigationSubsection(props: NavigationSubsection) {
    const { nodes, open = true } = props
    return (
        <StyledCollapse
            unmountOnExit
            in={open}
            timeout="auto">
            <StyledList
                disablePadding
                open={open.toString()}>
                {nodes.map(({ name, active = false, url }) => (
                    <ListItem
                        key={url}
                        disableGutters>
                        <StyledLink
                            active={active.toString()}
                            button
                            fullWidth
                            color={active ? "secondary" : undefined}
                            href={url}
                            startIcon={<Icon name="arrowRight" />}>
                            <Typography noWrap>{name}</Typography>
                        </StyledLink>
                    </ListItem>
                ))}
            </StyledList>
        </StyledCollapse>
    )
}

const StyledCollapse = styled(Collapse)({
    flex: "0 0 100%"
})

const StyledList = styled(List)<{ open: string }>(({ open, theme }) => ({
    backgroundColor:
        open === "true" ? theme.palette.background.default : "transparent",
    transition: "background-color .2s",
    padding: 0,
    position: "relative",
    zIndex: 1,
    display: "flex",
    flexWrap: "wrap",
    textOverflow: "ellipsis",
    "& .MuiListItem-root": {
        padding: 0
    }
}))

const StyledLink = styled(Link)<{ active: string }>(({ active, theme }) => ({
    flex: 1,
    borderRadius: 0,
    justifyContent: "flex-start",
    minWidth: 48,
    height: 48,
    padding: theme.spacing(1, 1.25),
    maxWidth: theme.constants.width.leftAside,
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",

    ...(active === "true" && {
        color: theme.palette.secondary.main,
        backgroundColor: `${theme.palette.secondary.main}22`,
        fontWeight: 700
    }),
    "& .MuiButton-startIcon": {
        color: active === "true" ? "initial" : "transparent"
    },

    "&:focus": {
        boxShadow: `inset 0 0 0 3px ${theme.palette.secondary.main}`
    }
}))
